<template>
  <div id="app">
    <div id="webgl" style="position: relative;"></div>

    <img src="@/assets/znz.png" class="zhinanz " id="zhinanz">
    <img src="@/assets/top.png" class="top">
    <img src="@/assets/full0.png" class="full" :style="{
      top: H - 80 + 'px'
    }" @click="setFull(1)" v-if="!full">
    <img src="@/assets/full1.png" class="full" :style="{
      bottom: '20px'
    }" @click="setFull(0)" v-if="full">
    <div id="foot" style="width: 100%;position: relative;margin-top: -20px;" ref="foot" v-if="!full">
      <div class="hBox flex ">
        <div class="col20 flex flexcenter hBoxItem" v-for="(item, index) in hList" :key="index" :style="{
      backgroundColor: item.color
    }">
          {{ item.title }}
        </div>
      </div>
      <div class="B" style="display: flex;overflow: scroll;width: 100%;">
        <div :class="['item', Bindex === 0 ? 'Bactive' : '']" @click="setBindex(0)">全部</div>
        <div v-for="(item, index) in B" :key="index" @click="setBindex(index + 1)" :class="['item', 'flex', 'flexcenter', Bindex === index + 1 ? 'Bactive' : '']">
          {{ item.name.replace(/[^\d]/g, " ") }}#
        </div>
      </div>
      <div class="flex between">
        <div class="flex around hcenter" style="width: 80%;">
          <div>08:00</div>
          <div style="width: 200px;">
            <van-slider v-model="value" @input="onChange" max="48" min="0">
              <template #button>
                <div class="custom-button">{{ time }}</div>
              </template>
            </van-slider>
          </div>
          <div>16:00</div>
        </div>
        <div style="width: 20%;" class="flex flexcenter">
          <div style="background-color: orange;width: 45px;height: 25px;border-radius: 20px;" class="flex flexcenter" @click="player = !player">
            <img :src="player ? play1Img : playImg" style="width: 34px;height: 20px;">
          </div>
        </div>
      </div>

      <div class="flex between season">
        <div v-for="(item, index) in season" :key="index" class="col33 flex flexcenter item">
          <div style="width: 50px;height: 28px;border-radius: 15px;" :class="['flexcenter', 'flex', index === seasonIndex ? 'active' : '']" @click="setSeasonIndex(index)"> {{
      item.name }}</div>
        </div>
      </div>

    </div>
    <div v-if="data.tietu">
      <div class="tag flex flexcenter" :id="item.name" v-for="(item, index) in data.tietu.filter(item1 => ['B', 'P'].includes(item1.name[0]))" :key="index" style="width: 80px;height: 16px;border-radius: 20px;background-color: cyan;font-size: 12px;border: 1px solid #000;">
        {{ item.name.replace('B_', '') }}栋
      </div>
    </div>
    <!-- <DiscountsTabel></DiscountsTabel> -->
    <div :style="{
      position: 'fixed',
      background: `url(${backgroundUrl}) no-repeat 100% /100%`,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh'
    }" v-if="advertisingStatus"></div>
    <div style="position: fixed;background-color: #f1f1f1;top: 0;right: 0;width: 40vw;padding: 20px;" :style="{ height: H + 'px' }" v-if="author">
      <div>注意：如果被挡住可以鼠标邮件拖动模型位置,确定修改只对当前季节有效</div>
      <div>
        <div class="">
          上半天时间指数<input type="text" v-model="season[seasonIndex].xMax" />默认({{ season_copy[seasonIndex].xMax }})
        </div>
        <div class="">
          下半天时间指数<input type="text" v-model="season[seasonIndex].xMin" />默认({{ season_copy[seasonIndex].xMin }})
        </div>
        <div class="">
          太阳中午偏差值 <input type="text" v-model="season[seasonIndex].x" />默认({{ season_copy[seasonIndex].x }})
        </div>
        <div class="">
          太阳最高点(中午)<input type="text" v-model="season[seasonIndex].y" />默认({{ season_copy[seasonIndex].y }})
        </div>
        <div class="">
          太阳最低点(早8或者下午4)<input type="text" v-model="season[seasonIndex].yMin" />默认({{ season_copy[seasonIndex].yMin }})
        </div>
        <div class="">
          太阳最前点<input type="text" v-model="season[seasonIndex].zMax" />默认({{ season_copy[seasonIndex].zMax }})
        </div>
        <div class="">
          太阳最后点<input type="text" v-model="season[seasonIndex].zMin" />默认({{ season_copy[seasonIndex].zMin }})
        </div>
        <div style="display: flex;justify-content:space-around">
          <van-button type="warning" @click="cz">重置</van-button>
          <van-button type="info" @click="save">保存</van-button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
// 引入gltf模型加载库GLTFLoader.js
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
// 引入dat.gui.js的一个类GUI
import { GUI } from 'three/addons/libs/lil-gui.module.min.js';
import axios from 'axios'
// 引入CSS3渲染器CSS3DRenderer
//import { CSS3DRenderer, CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';
import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
// import DiscountsTabel from '@/components/discountsTabel.vue'


export default {
  components: {
    /* DiscountsTabel */
  },
  data() {
    return {
      url: 'https://jzgft.com/',
      //url: ' http://192.168.1.103:8090/',
      id: 0,
      author: 0,
      scene: new THREE.Scene(),//模型
      time: '08:00',//当前时间
      value: 0,//当前时间
      directionalLight: "",//灯光
      directionalLight1: '',
      camera: '',//相机
      renderer: '',//渲染器,
      controls: '',
      season: [
        {
          name: '春分',
          name1: 'cf',
          xMax: 118,//太阳x轴
          x: -3,
          xMin: -110,
          y: 70,//太阳正午
          yMin: 52,//太阳刚出
          zMax: 32,//太阳刚出
          zMin: 37,//太阳正午
        },
        {
          name: '夏至',
          name1: 'xz',
          xMax: 100,//太阳x轴
          x: -3,
          xMin: -100,
          y: 125,//太阳正午
          yMin: 75,//太阳刚出
          zMax: -15,//太阳刚出
          zMin: 10,//太阳正午
        },
        {
          name: '秋分',
          name1: 'qf',
          xMax: 145,//太阳x轴
          x: -8,
          xMin: -125,
          y: 112,//太阳正午
          yMin: 65,//太阳刚出
          zMax: 40,//太阳刚出
          zMin: 58,//太阳正午
        },
        {
          name: '立冬',
          name1: 'ld',
          xMax: 140,//太阳x轴
          x: -14,
          xMin: -110,
          y: 90,//太阳正午
          yMin: 46,//太阳刚出
          zMax: 70,//太阳刚出
          zMin: 90,//太阳正午
        },
        {
          name: '冬至',
          name1: 'dz',
          xMax: 310,//太阳x轴
          x: 0,
          xMin: -350,
          y: 160,//太阳正午
          yMin: 84,//太阳刚出
          zMax: 222.72,//太阳刚出
          zMin: 222.72,//太阳正午
        },
        {
          name: '大寒',
          name1: 'dh',
          xMax: 310,//太阳x轴
          x: 0,
          xMin: -320,
          y: 188,//太阳正午
          yMin: 90,//太阳刚出
          zMax: 200,//太阳刚出
          zMin: 222.72,//太阳正午
        }
      ],
      season_copy: [
        {
          name: '春分',
          name1: 'cf',
          xMax: 118,//太阳x轴
          x: -3,
          xMin: -110,
          y: 70,//太阳正午
          yMin: 52,//太阳刚出
          zMax: 32,//太阳刚出
          zMin: 37,//太阳正午
        },
        {
          name: '夏至',
          name1: 'xz',
          xMax: 100,//太阳x轴
          x: -3,
          xMin: -100,
          y: 125,//太阳正午
          yMin: 75,//太阳刚出
          zMax: -15,//太阳刚出
          zMin: 10,//太阳正午
        },
        {
          name: '秋分',
          name1: 'qf',
          xMax: 145,//太阳x轴
          x: -8,
          xMin: -125,
          y: 112,//太阳正午
          yMin: 65,//太阳刚出
          zMax: 40,//太阳刚出
          zMin: 58,//太阳正午
        },
        {
          name: '立冬',
          name1: 'ld',
          xMax: 140,//太阳x轴
          x: -14,
          xMin: -110,
          y: 90,//太阳正午
          yMin: 46,//太阳刚出
          zMax: 70,//太阳刚出
          zMin: 90,//太阳正午
        },
        {
          name: '冬至',
          name1: 'dz',
          xMax: 310,//太阳x轴
          x: 0,
          xMin: -350,
          y: 160,//太阳正午
          yMin: 84,//太阳刚出
          zMax: 222.72,//太阳刚出
          zMin: 222.72,//太阳正午
        },
        {
          name: '大寒',
          name1: 'dh',
          xMax: 310,//太阳x轴
          x: 0,
          xMin: -320,
          y: 188,//太阳正午
          yMin: 90,//太阳刚出
          zMax: 200,//太阳刚出
          zMin: 222.72,//太阳正午
        }
      ],
      seasonIndex: 5,
      B: [],//楼盘
      Bindex: 0,
      hList: [{
        title: 'T=8h',
        color: '#61C969',
      },
      {
        title: '8h>T>=6h',
        color: '#9AD56D'
      },
      {
        title: '6h>T>=4h',
        color: '#E7DFA6'
      },
      {
        title: '4h>T>=2h',
        color: '#F3CF9E'
      },
      {
        title: 'T<2h',
        color: '#F5BBB3'
      }],
      data: {
        tietu: []
      },
      modelImgUrl: 'https://goufangt.oss-cn-beijing.aliyuncs.com',
      player: false,
      playImg: require('@/assets/play.png'),
      play1Img: require('@/assets/play1.png'),
      Interval: '',
      advertisingStatus: true,
      backgroundUrl: '',
      H: '',
      full: false,
      css3Renderer: '',
      uni: window.uni,
      key: 'gft'
    }
  },
  watch: {
    player(e) {//自动播放
      if (e) {
        this.Interval = setInterval(() => {
          if (this.value === 48) {
            this.value = 1;
          }
          this.value += 1;
          this.onChange(this.value, false)
        }, 200)
        return
      }
      clearInterval(this.Interval)
    },
    season: {
      handler() {
        setTimeout(() => {
          this.onChange(this.value, false)
        }, 200)
      },
      deep: true
    }
  },

  created() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');
    this.id = params.get('id');
    document.title = params.get('title') || '3D';
    this.author = params.get('author');
    let entrance = params.get('entrance');
    axios.get(`${this.url}/api/Property/background_url?id=${id}&entrance=${entrance}`).then(res => {
      this.backgroundUrl = res.data.data;
      this.initF(id);
    })

    this.getSeason();
    document.onkeydown = (event) => {
      if (event.keyCode === 39) {
        this.onChange(this.value += 1)
      }
      if (event.keyCode === 37) {
        this.onChange(this.value -= 1)
      }
    };
  },
  methods: {
    //全屏
    setFull(e) {
      if (e) {
        this.full = true;
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.css3Renderer.setSize(window.innerWidth, window.innerHeight);
        this.camera.updateProjectionMatrix();
      } else {
        this.full = false;
        this.renderer.setSize(window.innerWidth, this.H);
        this.camera.aspect = window.innerWidth / this.H;
        this.css3Renderer.setSize(window.innerWidth, this.H);
        this.camera.updateProjectionMatrix();
      }
    },
    initF(id) {
      this.$nextTick(() => {

        let H = 400;// window.innerHeight - document.getElementById('foot').offsetHeight;
        this.H = H;
        //this.createAuxiliaryLine();//创建辅助线
        // 2、创建相机
        this.camera = new THREE.PerspectiveCamera(
          75,
          window.innerWidth / H,
          0.1,
          3000
        );
        let camera = this.camera
        // 设置相机位置
        this.scene.add(camera);
        // 添加天空盒
        const loader1 = new THREE.TextureLoader();
        const texture = loader1.load(
          '1dfa152bc2053888d41b32eadc33249724b638a5_2.jpg',
          () => {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            texture.colorSpace = THREE.SRGBColorSpace;
            scene.background = texture;
          });
        // // 创建平面
        // const planeGeometry = new THREE.PlaneGeometry(800, 800); // 参数：宽度、高度
        // // 创建标准材质
        // const materialplane = new THREE.MeshStandardMaterial();
        // const plane = new THREE.Mesh(planeGeometry, materialplane); // 根据几何体和材质创建平面
        // plane.position.set(0, 0, 0); // 设置平面位置
        // plane.rotation.x = -Math.PI / 2; // 设置平面旋转
        // // 接收阴影
        // plane.receiveShadow = true;
        // this.scene.add(plane);
        // // 灯光
        // 环境光
        //直线光源模拟太阳
        this.directionalLight = new THREE.DirectionalLight(0xffffff, 5)// 参数：光源颜色、光源强度   DirectionalLight  SpotLight
        // this.directionalLight.position.set(this.season[0].xMax, this.season[0].y - 8, this.season[0].zMax); // 设置光源位置
        this.onChange(0, true)
        this.directionalLight.castShadow = true; // 设置光照投射阴影
        this.directionalLight.shadow.mapSize.set(4096, 4096);
        this.directionalLight.shadow.radius = 1;
        // 设置三维场景计算阴影的范围
        //太阳大小  
        this.directionalLight.shadow.camera.left = -50;
        this.directionalLight.shadow.camera.right = 50;
        this.directionalLight.shadow.camera.top = 50;
        this.directionalLight.shadow.camera.bottom = -50;
        this.directionalLight.shadow.camera.near = 0.5;
        this.directionalLight.shadow.camera.far = H;
        this.scene.add(this.directionalLight);
        //环境光模拟现实无所不在的光线
        const ambient = new THREE.AmbientLight(0xffffff, 0.4);
        this.scene.add(ambient);
        // DirectionalLightHelper：可视化平行光
        // const dirLightHelper = new THREE.DirectionalLightHelper(this.directionalLight, 5, 0xff0000);
        // this.scene.add(dirLightHelper);
        console.log(GUI)
        // const gui = new GUI();//创建GUI对象 
        // gui.add(this.directionalLight.position, 'x', -1000, 1000);
        // gui.add(this.directionalLight.position, 'y', -1000, 1000);
        // gui.add(this.directionalLight.position, 'z', -1000, 1000);
        // gui.domElement.style.width = '200px';
        // // 聚广源辅助对象，可视化聚广源
        const spotLightHelper = new THREE.SpotLightHelper(this.directionalLight, 0xffffff)
        this.scene.add(spotLightHelper);

        // 初始化渲染器
        this.renderer = new THREE.WebGLRenderer();

        let renderer = this.renderer
        // 设置渲染的尺寸大小
        this.renderer.setSize(window.innerWidth, H);
        // 开启场景中的阴影贴图
        this.renderer.shadowMap.enabled = true;
        //点击模型
        renderer.domElement.addEventListener('click', (event) => {
          const px = event.offsetX;
          const py = event.offsetY;
          const x = (px / window.innerWidth) * 2 - 1;
          const y = -(py / H) * 2 + 1;
          const raycaster = new THREE.Raycaster();
          raycaster.setFromCamera(new THREE.Vector2(x, y), camera);
          const intersects = raycaster.intersectObjects(this.B);
          if (intersects.length) {
            let index = this.B.findIndex(item => intersects[0].object.name === item.name)
            this.setBindex(index + 1);
          }
        })

        // 将webgl渲染的canvas内容添加到body
        // document.body.appendChild(this.renderer.domElement);
        document.getElementById('webgl').appendChild(this.renderer.domElement);
        // 创建轨道控制器
        this.controls = new OrbitControls(camera, this.renderer.domElement);
        // 设置控制器阻尼，让控制器更有真实效果,必须在动画循环里调用.update()。
        let controls = this.controls
        controls.maxDistance = 100;
        controls.minDistance = 15;
        controls.minZoom = 0;
        controls.maxZoom = 100;
        controls.maxPolarAngle = Math.PI / 2.4;
        controls.enableDamping = true;
        let scene = this.scene
        // 创建一个CSS3渲染器CSS3DRenderer
        this.css3Renderer = new CSS2DRenderer();
        this.css3Renderer.setSize(window.innerWidth, H);
        this.css3Renderer.domElement.style.position = 'absolute';
        this.css3Renderer.domElement.style.top = '0px';
        //设置.pointerEvents=none，解决HTML元素标签对threejs canvas画布鼠标事件的遮挡
        this.css3Renderer.domElement.style.pointerEvents = 'none';
        document.body.appendChild(this.css3Renderer.domElement);

        //创建模型
        // 创建GLTF加载器对象

        axios.get(`${this.url}/api/Property/get_model?id=${id}`).then(res => {


          let model = res.data.data.model
          model = model.slice(0, -1) + '.glb';

          const loader = new GLTFLoader();
          this.data = res.data.data;
          loader.load(model, gltf => {
            console.log('控制台查看加载gltf文件返回的对象结构', gltf);
            console.log('gltf对象场景属性', gltf.scene);

            camera.lookAt(0, 0, 0);
            controls.target.set(0, 0, 0);

            const box = new THREE.Box3().setFromObject(gltf.scene);
            const center = new THREE.Vector3();
            box.getCenter(center);
            let x = ((box.max.x - box.min.x) / 2) - box.max.x
            let z = ((box.max.z - box.min.z) / 2) - box.max.z
            let y = Math.abs(box.min.y)
            camera.position.set(0, ((box.max.y - box.min.y) / 0.5), ((box.max.x - box.min.x) / 2.5));
            gltf.scene.position.set(x, y, z);
            gltf.scene.traverse(obj => {
              console.log(obj.name, obj.isMesh, 515)
              if (obj.isMesh) {

                obj.material = new THREE.MeshLambertMaterial()
                obj.castShadow = true;
                obj.receiveShadow = true
                if (obj.name === 'Ground') {
                  obj.castShadow = false
                  let url = res.data.data.tietu.find(item => item.name === "Ground").url[0]
                  const texLoader = new THREE.TextureLoader().load(url);
                  texLoader.encoding = THREE.LinearEncoding;//默认值
                  texLoader.encoding = THREE.sRGBEncoding;
                  texLoader.flipY = false;
                  obj.material.map = texLoader
                  obj.material.transparent = true
                }
                if (['B', 'P'].includes(obj.name[0])) {
                  const box1 = new THREE.Box3().setFromObject(obj);
                  let x11 = ((box1.max.x - box1.min.x) / 2) - 2
                  const div = document.getElementById(obj.name);
                  // HTML元素转化为threejs的CSS3模型对象
                  const tag = new CSS2DObject(div);
                  div.style.pointerEvents = 'none';
                  tag.scale.set(0.04, 0.04, 0.04);
                  tag.position.set(box1.max.x + x - 2 - x11, box1.max.y + 3 + y, box1.max.z + z - 1);
                  this.scene.add(tag)
                  this.B.push(obj)
                }

              }
            });
            this.scene.add(gltf.scene);
            this.setModelImg();
            this.advertisingStatus = false;
          })
        })
        controls.addEventListener('change', function () {
          const dirx = camera.position.x - controls.target.x;
          const dirz = camera.position.z - controls.target.z;
          const theta = Math.atan2(dirx, dirz) * 180 / Math.PI;
          const compass = document.getElementById('zhinanz');
          compass.style.transform = 'rotateZ(' + theta + 'deg)';

        });//监听鼠标、键盘事件
        window.onresize = () => {
          // // 重置渲染器输出画布canvas尺寸
          // renderer.setSize(window.innerWidth, window.innerHeight);
          // // 全屏情况下：设置观察范围长宽比aspect为窗口宽高比
          // camera.aspect = window.innerWidth / window.innerHeight;
          // // 渲染器执行render方法的时候会读取相机对象的投影矩阵属性projectionMatrix
          // // 但是不会每渲染一帧，就通过相机的属性计算投影矩阵(节约计算资源)
          // // 如果相机的一些属性发生了变化，需要执行updateProjectionMatrix ()方法更新相机的投影矩阵
          // camera.updateProjectionMatrix();
        };

        let that = this;

        function render() {
          controls.update();
          renderer.render(scene, camera);
          that.css3Renderer.render(scene, camera);
          //   渲染下一帧的时候就会调用render函数
          requestAnimationFrame(render);
        }
        render();

        this.player = true//开始自动播放 不需要就删除


      })
    },
    //创建辅助线
    createAuxiliaryLine() {
      const axesHelper = new THREE.AxesHelper(150);
      this.scene.add(axesHelper);
    },
    //时间线扯动
    onChange(value, player = true) {

      //let season = (id == 8 ? this.season1 : this.season);
      let season = this.season
      let hour = 8;
      let overHour = parseInt(value / 6);
      let overS = value % 6;
      let h = (hour + overHour) >= 10 ? `${hour + overHour}` : `0${hour + overHour}`
      let i = overS === 0 ? `00` : `${10 * overS}`
      this.time = `${h}:${i}`


      let x1 = season[this.seasonIndex].x
      let x = 0
      if (24 - value > 0) {//上午
        x = season[this.seasonIndex].xMax / 24
      }
      if (24 - value < 0) {//下午
        x = Math.abs(season[this.seasonIndex].xMin / 24)
      }
      let z = season[this.seasonIndex].zMax
      let zTB = season[this.seasonIndex].zMin

      let zp = (season[this.seasonIndex].zMax - season[this.seasonIndex].zMin) / 24
      z = zTB += (Math.abs(24 - value) * zp)

      let yblc = (season[this.seasonIndex].y - season[this.seasonIndex].yMin) / 24
      let y = season[this.seasonIndex].y - (Math.abs(24 - value) * yblc)
      this.directionalLight.position.set((24 - value) * x + x1, y, z); // 设置光源位置
      if (player) {
        clearInterval(this.Interval)
        this.player = false
      }

    },

    setSeasonIndex(index) {
      this.seasonIndex = index;
      this.value = 0;
      this.time = '08:00'
      this.onChange(0, false)
      this.setModelImg(index)
    },
    //切换贴皮
    setModelImg(jijie = this.seasonIndex) {

      let arr = this.data.tietu ? this.data.tietu.filter(item => ['B', 'P'].includes(item.name[0])) : []

      this.B.forEach((item, index) => {

        let texLoader
        if (arr[index].url.length === 1) {
          texLoader = new THREE.TextureLoader().load(arr[index].url[0]);
        } else {
          texLoader = new THREE.TextureLoader().load(arr[index].url[jijie]);
        }
        //texLoader.encoding = THREE.LinearEncoding;//默认值
        texLoader.encoding = THREE.sRGBEncoding;
        texLoader.flipY = false;
        item.material.map = texLoader
        item.material.transparent = true
      })
    },
    //切换栋数
    setBindex(d) {
      this.Bindex = d
      this.B.forEach((item, index) => {
        if (d) {
          if (d === index + 1) {
            item.material.opacity = 1
          } else {
            item.material.opacity = 0.3
          }
        } else {
          item.material.opacity = 1
        }
      })
    },
    //保存
    save() {
      axios.post(`${this.url}/api/Property/setSeason`, {
        id: this.id,
        data: JSON.stringify(this.season[this.seasonIndex])
      }).then(res => {

        if (res.data.code === 1) {
          this.$dialog.alert({
            message: '保存成功',
          });
        }
      })
    },
    getSeason() {
      axios.get(`${this.url}/api/Property/getSeason?id=${this.id}`).then(res => {
        let data = res.data.data
        for (var key in data) {
          if (data[key]) {
            let index = this.season.findIndex(item => item.name1 === JSON.parse(data[key]).name1);
            this.$set(this.season, index, JSON.parse(data[key]))
          }
        }
      })
    },
    cz() {
      this.$set(this.season, this.seasonIndex, JSON.parse(JSON.stringify(this.season_copy[this.seasonIndex])))
      // this.$set(this, 'season', this.season_copy)
    }
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
}

.full {
  position: absolute;
  z-index: 10;
  right: 20px;
  width: 40px;
}

.zhinanz {
  position: absolute;
  z-index: 9;
  right: 20px;
  top: 20px;
  width: 70px;
}

.top {
  position: absolute;
  z-index: 10;
  right: 50px;
  top: 50px;
  width: 10px;
}

.tag {
  color: #000;
}

.hBox {
  font-size: 12px;

  .hBoxItem:first-child {
    border-radius: 20px 0px 0px 0px;
  }

  .hBoxItem:last-child {
    border-radius: 0px 20px 0px 0;
  }

  .hBoxItem {
    line-height: 40px;
  }
}

.B {
  .Bactive {
    color: orange !important;
  }

  .item:first-child {
    flex: 0 0 auto;
    width: 40px;
  }

  .item {
    color: #666;
    margin: 0 10px;
  }

  margin: 10px 0;
  padding: 10px 0;
}

.custom-button {
  width: 26px;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  background-color: orange;
  border-radius: 100px;
  padding: 0 4px;
}

.season {
  .item {
    margin: 10px 0;

    div {
      border: 1px solid orange;
    }

    .active {
      background-color: orange;
      color: #fff;
    }
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.hcenter {
  align-items: center;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.flexcenter {
  justify-content: center;
  align-items: center;
}

.col20 {
  width: 20%;
}

.col33 {
  width: 33%;
}
</style>
